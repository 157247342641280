<template>
    <div class="welcome">
        <div class="welcome-container">
            <h1>{{ $t('welcome_to') }}</h1>
            <img :src="require('@/assets/img/logo.png')" alt="logo" class="logo">
            <p class="desc">{{ $t('welcome_desc') }}</p>

            <div class="item-container">
                <div class="item" v-for="(item, key) in items" :key="key">
                    <img :src="require(`@/assets/img/${item.img}`)" :alt="item.img" class="icon">
                    <p class="title">
                        {{ item[`title_${language}`] }}
                    </p>
                    <p class="detail">
                        {{ item[`desc_${language}`] }}
                    </p>
                </div>
            </div>

            <button @click="$router.push('/dashboard').catch(()=>{})">{{ $t('Close') }}</button>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    data () {
        return {
            items: [
                {
                    img: 'welcome_1.png',
                    title_en: 'Interest and preference',
                    title_cn: '興趣偏好',
                    desc_en: 'Based on the choices you’ve made throughout the game, we analyze the things that you enjoy the most and list down activities that have become part of your habit. First step in getting a better understanding of your self',
                    desc_cn: '根據您在測驗中所做的選擇，我們分析出您最喜歡的事情，並列出已成為您習慣一部分的活動。第一步是更好地了解自己！'
                },
                {
                    img: 'welcome_2.png',
                    title_en: 'Technical and Vocational Education Academic Subjects to Consider “Technical and Vocational Subjects to Consider”',
                    title_cn: '參考的技職15群科',
                    desc_en: 'Out of 15 subject groups, the top Technical and Vocational subject/s that is well suited to your interests and skills is selected giving you a well thought-off suggestion.',
                    desc_cn: '已經從15個技職學科群科中，選出最適合您的興趣和技巧學科，為您提供深思熟慮的建議。'
                },
                {
                    img: 'welcome_3.png',
                    title_en: 'University Academic Subjects to Consider',
                    title_cn: '參考的大學18學群',
                    desc_en: 'Out of 18 subject groups, academic subject/s are listed according to your interests and skills. Check out the subjects that are best for you!',
                    desc_cn: '已根據您的興趣和技巧，從大學的18學群當中列出符合的專業。看看最適合你的科目有哪些！'
                },
                {
                    img: 'welcome_4.png',
                    title_en: 'Sample Careers',
                    title_cn: '參考職業領域',
                    desc_en: 'Based on your overall preferences during the digital card game, sample career/s that could be interesting for you is listed to help you streamline career path you would like to take.',
                    desc_cn: '根據您在卡牌測驗中的整體偏好，列出了您可能感興趣的職業類型，以幫助您簡化您希望採取的職業道路。”'
                },
                
            ]
        }
    },

    computed: {
        ...mapFields(['language'])
    }
}
</script>

<style lang="scss" scoped>
.welcome {
    padding: 20px 0 20px;
    display: flex;
    justify-content: center;

    .welcome-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #221E1F;
        padding: 20px;

        h1 {
            font-family: 'Crimson Pro', sans-serif;
            font-weight: 700;
            font-size: 38px;
            margin-bottom: 10px;
        }
        
        .logo {
            width: 225px;
            height: 145px;
            margin-bottom: 30px;
        }

        .desc {
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 45px;
            text-align: center;

            @include desktop {
                width: 800px;
            }
        }

        .item-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            margin-bottom: 55px;

            .item {
                width: 250px;
                margin-right: 10px;
                margin-bottom: 20px;
                text-align: center;

                @include desktop {
                    margin-right: 35px;
                }

                &:last-child {
                    margin-right: 0px;
                }

                .icon {
                    width: 120px;
                    height: 120px;
                    margin-bottom: 20px;
                }
    
                .title {
                    font-family: 'Crimson Pro', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    height: 80px;
                    margin-bottom: 20px;
                }

                .detail {
                    color: #B8B8B8;
                    font-size: 14px;
                    // width: 220px;
                    margin: auto;
                }
            }
        }

        button {
            width: 126px;
            background: #00AEEF;
            color: #ffffff;
            font-weight: 600;
            margin-bottom: 30px;
        }
    }
}
</style>